import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

import NavBar from "./components/NavBar";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div>
      {authIsReady && (
          <Router>
            {user ? <NavBar />: null}
            <Routes>
              <Route
                path="/"
                element={!user ? <Navigate to="/login" /> : <Home />}
              />
              <Route
                path="/login"
                element={user ? <Navigate to="/" /> : <Login />}
              />
              <Route
                path="/*"
                element={user ? <Navigate to="/" /> : <Login />}
              />
            </Routes>
          </Router>
      )}
    </div>
  );
}

export default App;
