import { useLogin } from "../../hooks/useLogin";
import styles from "./Login.module.css";
import {
  Box,
  Flex,
  Heading,
  Text,
  Center,
  Container,
  Stack,
  Image,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export default function Login() {
  const { login, error, isPending } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "24", md: "48" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
          boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <Center>
              <Image
                src="/caverion_c.png"
                alt="caverion_logo"
                style={{ height: 50, width: 50 }}
              />
            </Center>
            <Center>
              <form onSubmit={handleSubmit} className={styles["login-form"]}>
                {!isPending && (
                  <Center>
                    <button className="btn">Log in with Google</button>
                  </Center>
                )}
                {isPending && (
                  <button className="btn" disabled>
                    loading
                  </button>
                )}
                {error && (
                  <Box textAlign="center" py={10} px={6}>
                    <Box display="inline-block">
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bg={"red.500"}
                        rounded={"50px"}
                        w={"55px"}
                        h={"55px"}
                        textAlign="center"
                      >
                        <CloseIcon boxSize={"20px"} color={"white"} />
                      </Flex>
                    </Box>
                    <Heading as="h2" size="xl" mt={6} mb={2}>
                      Something went wrong.
                    </Heading>
                    <Text color={"gray.500"}>
                      Error: {error}
                    </Text>
                  </Box>
                )}
              </form>
            </Center>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}
