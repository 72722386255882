import { useState, useEffect } from "react";
import { outputBucket } from "../firebase/config";
import { Heading, Button, Stack, Flex } from "@chakra-ui/react";
import OutputItem from "./OutputItem";
import { useCustomToast } from "../hooks/useCustomToast";

export default function OutputBucketList() {
  const [allFiles, setFiles] = useState([]);
  const { triggerToast } = useCustomToast();

  const getFromFirebase = () => {
    let storageRef = outputBucket.ref();
    storageRef
      .listAll()
      .then(async function (res) {
        res.items.forEach((fileRef) => {
          fileRef.getMetadata().then((meta) => {
            if (allFiles.indexOf(meta) === -1) {
              setFiles((allFiles) => [...allFiles, meta]);
            }
          });
        });
        triggerToast({
          title: "Output Files refreshed.",
          status: "success",
          duration: 2000,
        });
      })
      .catch(function (error) {
        triggerToast({
          title: "Error occurred.",
          status: "error",
          description: error.message,
        });
      });
  };

  const deleteFromFirebase = (ref) => {
    let fileRef = outputBucket.ref().child(ref.fullPath);
    fileRef
      .delete()
      .then(() => {
        setFiles(allFiles.filter((file) => file !== ref));
        triggerToast({
          title: "Output File was deleted successfully.",
          status: "success",
          description: ref.fullPath,
          position: "bottom",
        });
      })
      .catch((err) => {
        triggerToast({
          title: "Error occurred.",
          description: err.message,
          status: "error",
          position: "bottom",
        });
      });
  };

  useEffect(() => {
    getFromFirebase();
    return () => {
      setFiles([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={{ base: "8", md: "10" }} flex="2" width={570}>
      <Flex direction="row" justify="space-between" align="center" p={0} m={0}>
        <Heading fontSize="2xl" fontWeight="extrabold">
          Output Files
        </Heading>
        <Button
          width="100px"
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            setFiles([]);
            getFromFirebase();
          }}
        >
          Refresh
        </Button>
      </Flex>

      <Stack spacing="3">
        {allFiles
          .filter(
            (file) =>
              file.contentType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.contentType === "application/octet-stream"
          )
          .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          .map((item) => (
            <OutputItem
              key={item.name}
              item={item}
              deleteFromFirebase={deleteFromFirebase}
            />
          ))}
      </Stack>
    </Stack>
  );
}
