import { InfoIcon, DownloadIcon } from "@chakra-ui/icons";
import { Flex, Stack, Box, Text, IconButton } from "@chakra-ui/react";
import { uiAssetsBucket } from "../firebase/config";
import { useCustomToast } from "../hooks/useCustomToast";

export default function TemplateFile() {
  const { triggerToast } = useCustomToast();

  const download = (item) => {
    const itemRef = uiAssetsBucket.ref().child("template_file.xlsx");
    itemRef
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {};
        xhr.open("GET", url);
        xhr.send();
        window.open(url);
        //  setMessage("File downloaded");
        triggerToast({ title: "Template File downloaded.", status: "success" });
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            triggerToast("File doesn't exist", "error");
            break;
          case "storage/unauthorized":
            triggerToast(
              "User doesn't have permission to access the object",
              "error"
            );
            break;
          case "storage/canceled":
            triggerToast("User canceled the upload", "error");
            break;
          case "storage/unknown":
            triggerToast(
              "Unknown error occurred, inspect the server response",
              "error"
            );
            break;
          default:
            triggerToast(error, "error");
            break;
        }
      });
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align="center"
      borderWidth="1px"
      rounded="md"
      p={2}
      mb={5}
      ml={0}
      w="100%"
    >
      <Stack direction="row" spacing="5" width="70%">
        <Box>
          <Text fontWeight="medium" color="teal" ml={2}>
            <InfoIcon mr={1} /> Template File
          </Text>
        </Box>
      </Stack>
      <Flex
        width="30%"
        justify="space-evenly"
        display={{ base: "none", md: "flex" }}
        align="center"
      >
        <IconButton
          aria-label={`Download template file`}
          onClick={() => download()}
          variant="ghost"
          icon={<DownloadIcon />}
        />
      </Flex>
    </Flex>
  );
}
