import { useState, useEffect } from "react";
import { inputBucket, outputBucket } from "../firebase/config";
import { Heading, Button, Stack, Flex } from "@chakra-ui/react";
import InputItem from "./InputItem";
import { useCustomToast } from "../hooks/useCustomToast";

export default function FilesList() {
  const [inputFiles, setInputFiles] = useState([]);
  const { triggerToast } = useCustomToast();

  const getFromFirebase = () => {
    let storageRef = inputBucket.ref();
    storageRef
      .listAll()
      .then(async function (res) {
        res.items.forEach((fileRef) => {
          fileRef.getMetadata().then((meta) => {
            if (inputFiles.indexOf(meta) === -1) {
              setInputFiles((inputFiles) => [...inputFiles, meta]);
            }
          });
        });
        triggerToast({
          title: "Input Files refreshed.",
          status: "success",
          duration: 2000,
        });
      })
      .catch(function (error) {
        triggerToast({
          title: "An error occurred.",
          status: "error",
          description: error.message,
        });
      });
  };

  const deleteFromFirebase = (ref) => {
    const fileRef = inputBucket.ref().child(ref.fullPath);
    const statusFilename =
      ref.fullPath.substring(0, ref.fullPath.lastIndexOf(".")) +
      " - results" +
      ref.fullPath.substring(ref.fullPath.lastIndexOf(".")) +
      ".json";
    const statusRef = outputBucket.ref().child(statusFilename);

    fileRef
      .delete()
      .then(() => {
        triggerToast({
          title: "File was deleted successfully.",
          description: `${ref.fullPath}`,
          status: "success",
          position: "bottom",
        });
        return statusRef.delete();
      })
      .then(() => {
        setInputFiles(inputFiles.filter((file) => file !== ref));
      })
      .catch((err) => {
        triggerToast({
          title: "An error occurred.",
          status: "error",
          description: err.message,
          position: "bottom",
        });
      });
  };

  useEffect(() => {
    getFromFirebase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={{ base: "8", md: "10" }} flex="2" width={570}>
      <Flex direction="row" justify="space-between" align="center" p={0} m={0}>
        <Heading fontSize="2xl" fontWeight="extrabold">
          Input Files
        </Heading>
        <Button
          width="100px"
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            setInputFiles([]);
            getFromFirebase();
          }}
        >
          Refresh
        </Button>
      </Flex>

      <Stack spacing="3">
        {inputFiles
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .map((item) => (
            <InputItem
              key={item.name}
              item={item}
              deleteFromFirebase={deleteFromFirebase}
              storage={inputBucket}
            />
          ))}
      </Stack>
    </Stack>
  );
}
