import { outputBucket } from "../firebase/config";
import { Flex, Text, Box, Stack, IconButton } from "@chakra-ui/react";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { useCustomToast } from "../hooks/useCustomToast";

export default function OutputItem({ item, deleteFromFirebase }) {
  const { triggerToast } = useCustomToast();

  const download = (item) => {
    const itemRef = outputBucket.ref().child(item.fullPath);
    itemRef
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {};
        xhr.open("GET", url);
        xhr.send();
        window.open(url);
        triggerToast({
          title: "Output File downloaded.",
          description: `${item.fullPath}`,
          status: "success",
          position: "bottom",
          duration: 6000,
        });
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            triggerToast({ title: "File doesn't exist", status: "error" });
            break;
          case "storage/unauthorized":
            triggerToast({
              title: "User doesn't have permission to access the object",
              status: "error",
            });
            break;
          case "storage/canceled":
            triggerToast({
              title: "User canceled the upload",
              status: "error",
            });
            break;
          case "storage/unknown":
            triggerToast({
              title: "Unknown error occurred, inspect the server response",
              status: "error",
            });
            break;
          default:
            triggerToast({
              title: "Error occurred.",
              description: error,
              status: "error",
            });
            break;
        }
      });
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align="center"
      borderWidth="1px"
      rounded="md"
      p={2}
    >
      <Stack direction="row" spacing="5" width="70%">
        <Box>
          <Stack spacing="0.5">
            <Text fontWeight="medium" color="teal" ml={2}>
              {item.name}
            </Text>
          </Stack>
        </Box>
      </Stack>
      <Flex
        width="30%"
        justify="space-evenly"
        display={{ base: "none", md: "flex" }}
        align="center"
      >
        <IconButton
          aria-label={`Download ${item.name} from bucket`}
          onClick={() => download(item)}
          variant="ghost"
          icon={<DownloadIcon />}
        />
        <IconButton
          variant="ghost"
          aria-label={`Delete ${item.name} from bucket`}
          icon={<DeleteIcon />}
          onClick={() => deleteFromFirebase(item)}
        />
      </Flex>
    </Flex>
  );
}
