import { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Box,
  Stack,
  IconButton,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { format, parseISO } from "date-fns";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { outputBucket } from "../firebase/config";
import axios from "axios";
import { useCustomToast } from "../hooks/useCustomToast";

export default function InputItem({ item, deleteFromFirebase, storage }) {
  const [isLoadingJson, setIsLoadingJson] = useState(true);
  const [itemJson, setItemJson] = useState();
  const { triggerToast } = useCustomToast();

  const download = (item) => {
    const itemRef = storage.ref().child(item.fullPath);
    itemRef
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {};
        xhr.open("GET", url);
        xhr.send();
        window.open(url);
        triggerToast({
          title: "Input File downloaded.",
          description: `${item.fullPath}`,
          status: "success",
          position: "bottom",
          duration: 6000,
        });
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            triggerToast({ title: "File doesn't exist", status: "error" });
            break;
          case "storage/unauthorized":
            triggerToast({
              title: "User doesn't have permission to access the object",
              status: "error",
            });
            break;
          case "storage/canceled":
            triggerToast({
              title: "User canceled the upload",
              status: "error",
            });
            break;
          case "storage/unknown":
            triggerToast({
              title: "Unknown error occurred, inspect the server response",
              status: "error",
            });
            break;
          default:
            triggerToast({
              title: "Error occurred.",
              description: error,
              status: "error",
            });
            break;
        }
      });
  };

  useEffect(() => {
    const extension = item.name.substring(item.name.lastIndexOf("."));
    outputBucket
      .ref()
      .child(
        item.name.substring(0, item.name.lastIndexOf(".")) +
          " - results" +
          extension +
          ".json"
      )
      .getDownloadURL()
      .then((url) => {
        axios.get(url).then((res) => {
          setItemJson(res.data);
          setIsLoadingJson(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align="center"
      borderWidth="1px"
      rounded="md"
      p={2}
    >
      <Stack direction="row" spacing="5" width="80%">
        <Box pt="3">
          <Stack spacing="0.5">
            <Text fontWeight="medium" color="teal" ml={2}>
              {item.name}
            </Text>
            <Table size="sm" variant="unstyled">
              <Tbody>
                <Tr>
                  <Td>Uploaded On:</Td>
                  <Td>
                    <Text color={"gray.500"}>
                      {format(parseISO(item.timeCreated), "MMMM dd yyyy HH:mm")}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Uploaded By:</Td>
                  <Td>
                    <Text color={"gray.500"}>
                      {item.customMetadata?.uploadedBy}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Status:</Td>
                  <Td>
                    {isLoadingJson ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : (
                      <Text color={"gray.500"}>{itemJson.message}</Text>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </Box>
      </Stack>
      <Flex
        width="20%"
        justify="space-evenly"
        display={{ base: "none", md: "flex" }}
        align="center"
      >
        <IconButton
          aria-label={`Download ${item.name} from bucket`}
          onClick={() => download(item)}
          variant="ghost"
          icon={<DownloadIcon />}
        />
        <IconButton
          // isDisabled={isLoadingJson}
          variant="ghost"
          aria-label={`Delete ${item.name} from bucket`}
          icon={<DeleteIcon />}
          onClick={() => deleteFromFirebase(item)}
        />
      </Flex>
    </Flex>
  );
}
