import { useState } from "react";
import {
  Collapse,
  Button,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/react";

export default function Instructions() {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Stack spacing={{ base: "4", md: "5" }} flex="2" mb={5}>
      <Heading fontSize="2xl" fontWeight="extrabold">
        Instructions
      </Heading>
      <Collapse startingHeight={120} in={show}>
        <Text fontSize="md">
          Click or move a new file onto the gray area on the right. Then click
          'Refresh' button beside 'Input Files'. The uploaded file should soon
          appear under Input Files. <br />
          <br />
          Status tells the current phase of the file in the processing pipeline.
          When the status becomes "Input file was processed successfully" the
          corresponding Output File will appear in 'Output Files' after pushing
          the 'Refresh' button next to Output Files. <br />
          <br />
          An example of a valid data structure in an input file can be downloaded on the right (template file).
        </Text>
      </Collapse>
      <Button size="sm" onClick={handleToggle} mt="1rem" width="100px">
        Show {show ? "Less" : "More"}
      </Button>

      
    </Stack>
  );
}
