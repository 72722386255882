// import { app as firebase } from "./config"
import { inputBucket } from "./config";

export const uploadFromBlobAsync = async ({ blobUrl, name, user }) => {
  if (!blobUrl || !name) return null;

  const customMetadata = {
    customMetadata: {
      uploadedBy: `${user}`,
    },
  };

  try {
    const blob = await fetch(blobUrl).then((r) => r.blob());
    const snapshot = await inputBucket
      .ref()
      .child(name)
      .put(blob, customMetadata);
    return await snapshot.ref.getDownloadURL();
  } catch (error) {
    throw error;
  }
};
