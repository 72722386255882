import styles from "./Home.module.css";
import DropZone from "../../components/DropZone";
import { Center, Divider, SimpleGrid } from "@chakra-ui/react";
import InputBucketList from "../../components/InputBucketList";
import OutputBucketList from "../../components/OutputBucketList";
import Instructions from "../../components/Instructions";
import TemplateFile from "../../components/TemplateFile";

export default function Home() {
  return (
    <>
      <SimpleGrid columns={1} mt={10}>
        <div className={styles.container}>
          <div className={styles.content}>
            <Instructions />
          </div>
          <div className={styles.sidebar}>
            <DropZone />
            <TemplateFile />
          </div>
        </div>
        <Center>
          <Divider w={800} />
        </Center>
      </SimpleGrid>
      <SimpleGrid columns={1} mt={10} mb={10}>
        <div className={styles.container}>
          <div className={styles.content}>
            <InputBucketList />
          </div>
          <div className={styles.sidebar}>
            <OutputBucketList />
          </div>
        </div>
      </SimpleGrid>
    </>
  );
}
