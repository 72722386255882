// import { initializeApp, getApps, getApp } from "firebase/app"; // newer version 9
import firebase from "firebase/compat/app";
// import { getAuth } from "firebase/auth"; // newer version 9
import "firebase/compat/auth";
// import { getStorage } from "firebase/storage"; // newer version 9
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

// const app = !getApps().length ? initializeApp(firebaseConfig) : getApp(); // newer version 9
// const auth = getAuth(app); // newer version 9
// const storageV9 = getStorage(); // newer version 9

if (!firebase.apps?.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const inputBucket = firebase.app().storage(process.env.REACT_APP_INPUT_BUCKET);
// const inputBucket = firebase.app().storage();
const outputBucket = firebase.app().storage(process.env.REACT_APP_OUTPUT_BUCKET);
const uiAssetsBucket = firebase.app().storage(process.env.REACT_APP_ASSETS_BUCKET);

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
const signInWithGoogle = () => auth.signInWithPopup(provider);

export { firebase, auth, inputBucket, outputBucket, signInWithGoogle, uiAssetsBucket };
