import { useToast } from "@chakra-ui/react";

export const useCustomToast = () => {
  const toast = useToast();

  const triggerToast = ({ title, status, description, position, duration }) => {
    toast({
      title: `${title}`,
      status: `${status}`,
      description: description ? `${description}` : "",
      duration: duration ? duration : 3000,
      isClosable: true,
      position: position ? `${position}` : "top",
    });
  };

  return { triggerToast };
};
