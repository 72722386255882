import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Flex,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Center,
} from "@chakra-ui/react";
import { useAuthContext } from "../hooks/useAuthContext";

import { uploadFromBlobAsync } from "../firebase/storage";
import { inputBucket } from "../firebase/config";

function fileNameValidator(file) {
  const regex = /^[a-zA-Z0-9_\-äöåÄÖÅ ]+$/;
  if (!regex.test(file.name.substring(0, file.name.lastIndexOf('.')))) {
    return {
      code: "unsupported-characters",
      message: `Only characters space, dash, underline, letters and numbers permitted in file name.`
    };
  }

  return null
}

export default function DropZone() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { user } = useAuthContext();

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setMessage(null);

    try {
      const isMeta = await inputBucket.ref().child(file.name).getMetadata();
      if (isMeta) {
        setIsLoading(false);
        setError("File already exists.");
        return;
      }
    } catch (e) {}

    try {
      await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `${file.name}`,
        user: user.displayName ? user.displayName : user.email,
      });
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
      return;
    }

    setIsLoading(false);
    setMessage("File was uploaded");
    setTimeout(() => {
      setMessage(null);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDropRejected = (fileRejections, event, ) => {
    setError(fileRejections[0].errors.map(error => error.message).join(' AND '));
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    validator: fileNameValidator
  });

  return (
    <div>
      <Flex
        justify="center"
        align="center"
        textAlign="center"
        bg="#dadada"
        w="100%"
        h={150}
        p={50}
        m={0}
        mb={5}
        borderRadius={5}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <Spinner />
        ) : isDragActive ? (
          <Text>Drop the file here...</Text>
        ) : (
          <Text>
            Drag 'n' drop an excel file here, or click to select a file
            <br />
            <em>(File must be in excel format AND only characters space, dash, underline, letters and numbers are permitted in the file name.)</em>
          </Text>
        )}
      </Flex>
      {(error || message) && (
        <Center>
          <Alert
            status={error ? "error" : "success"}
            w={300}
            borderRadius={5}
            m={2}
            mb={5}
          >
            <AlertIcon />
            <AlertDescription w={200}>{error || message}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => {
                setError(null);
                setMessage(null);
              }}
            />
          </Alert>
        </Center>
      )}
    </div>
  );
}
